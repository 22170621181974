import React, { useState } from "react";
import Helmet from "react-helmet";
import { Content } from "components/Content";
import { TextBlock } from "components/TextBlock";
import { List } from "components/List";
import { Hero } from "components/Hero";
import { images } from "components/HeroImages";

const albums = [
  {
    name: "Kabza De Small Day Party",
    src: "/gallery/kabza.jpg",
    href: "https://photos.app.goo.gl/75BvGQEzjm2mFywf9",
  },
  {
    name: "Zim Independence 2022",
    src: "/gallery/zim.jpg",
    href: "https://photos.app.goo.gl/QUz4efnZ3i9R5cLLA",
  },
  {
    name: "DBN GOGO Day Party",
    src: "/gallery/dbn_day.jpg",
    href: "https://photos.app.goo.gl/NwCKetqrb8F2CZTT7",
  },
  {
    name: "TXC",
    src: "/gallery/txc.jpg",
    href: "https://photos.app.goo.gl/kgnxq48oHEgKU79v8",
  },
  {
    name: "Champion Sound",
    src: "/gallery/champion.jpg",
    href: "https://photos.app.goo.gl/zM2Nmv7Fh7B41DKy5",
  },
  {
    name: "Bella Shmurda",
    src: "/gallery/bella.jpg",
    href: "https://photos.app.goo.gl/ydRsUQEHv2syUybE8",
  },
  {
    name: "Major League Djz(Birmigham)",
    src: "/gallery/major.jpg",
    href: "https://photos.app.goo.gl/y9CfWTanoT4vTKHy7",
  },
  {
    name: "DBN GOGO (Birmigham)",
    src: "/gallery/dbn_birmingham.jpg",
    href: "https://photos.app.goo.gl/ZZSSjyP1hxhHpxzZ7",
  },
  {
    name: "AfroBrunch",
    src: "/gallery/afro_brunch.jpg",
    href: "https://photos.app.goo.gl/apL29ycFN14Mj6Rq6",
  },
];

function GalleryPage(p) {
  const title = "Photo Gallery | Status100 Promotions";
  const description = "Images from our previous events.";

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero image={images.gallery}>
        <h1>Photo gallery</h1>
      </Hero>
      <Content>
        <List>
          <TextBlock align="center">
            <h3>Photo Gallery</h3>
            <p>Images from our previous events.</p>
          </TextBlock>
          <div
            className="gallery"
            style={{
              minHeight: "500px",
            }}
          >
            {albums.map((album) => (
              <div key={album.name} className="gallery__album">
                <img src={album.src} alt={album.name} />
                <div>
                  <h4>{album.name}</h4>
                  <a
                    href={album.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View full album
                  </a>
                </div>
              </div>
            ))}
          </div>
        </List>
      </Content>
    </React.Fragment>
  );
}

export default GalleryPage;
